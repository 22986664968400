import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";
import InternalButton from "../../components/internalButton";

const AGEU = () => {
    const folder = '/downloads/activeGalaxies'   
    const links = [
        {
            title:'Active Galaxies Poster',
            link:`${folder}/agn.jpg`,
            image:'activeGalaxies.png'
        },
        {
            title:'Back of the Active Galaxies Poster',
            link:`${folder}/agnposactigpo.pdf`,
            image:'agnpos.png'
        },
        {
            title:'Active Galaxies Poster(no text)',
            link:`${folder}/agnnotext.jpg`,
            image:'agposnotext.png'
        },
        {
            title:'Educator Guide',
            link:`${folder}/agnguide10a.pdf`,
            image:'agnguide.png'
        },
        {
            title:'Both Covers of Educator Guide',
            link:`${folder}/AGNactivcover03.jpg`,
            image:'agnactivecover.png'
        },
    ]

    return (
        <Layout>
            <div>
                <h1>Active Galaxy Education Unit</h1>
                <Star />
                <p>The Fermi E/PO team has developed a set of three activities to teach students about active galaxies. All three activities are aligned with the national science and mathematics standards. A beautiful poster illustrating an Active Galaxy features one of the three activities on the reverse side.</p>
                <div className='BHRA-button-container'>
                    {/* {links.map( (l,i) => <DownloadButton key={i} text={l.title} link={l.link} buttonStyles = {ButtonStylesClass}/>)} */}
                    
                    {links.map( (l,i) =>
                    <div className="item-container" style={{margin:'5% 0'}}>
                        <img src={`/${l.image}`} className='image-styles' alt='media'/> {/*change images*/}
                        <h4 style={{margin:'20px'}}>{l.title}</h4>
                        <DownloadButton  link={l.link} />
                    </div> 
                    )}
                    <div className="item-container" style={{margin:'5% 0'}}>
                        <img src={`/activeGalaxies.png`} className='image-styles' alt='media'/> {/*change images*/}
                        <h4 style={{margin:'20px'}}>Huge Black Holes: Measuring the Monster in the Middle</h4>
                        <InternalButton link={'/ppt/agnPPT'}/>
                    </div> 
                    {/* <InternalButton text='Huge Black Holes: Measuring the Monster in the Middle' link={'/ppt/agnPPT'} buttonStyles = {ButtonStylesClass}/> */}
                </div>
            </div>
        </Layout>
    )
}

export default AGEU
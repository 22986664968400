import React from "react"
import '../style.css'
import { FaArrowAltCircleRight } from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import {Link} from 'gatsby'


const InternalButton = ({text,link,buttonStyles}) => {
  //Created for ease of reuse
  return (
    <Link to={link} target='_blank'  rel="noreferrer" className={buttonStyles} style={{ display:'flex', justifyContent:'center'}}>
      <div>
          <p style={{color:'#facb65', textAlign:'center', marginBottom:'0'}}>{text}  <FaArrowAltCircleRight style={{width:'0.9em', margin:'5px'}}/></p>
      </div>
    </Link>
  )
}

InternalButton.defaultProps = {
    text:'Learn more',
    link:'/',
    buttonStyles:'media-item-buttons'
}

export default InternalButton